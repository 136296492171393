import {createAsyncThunk} from '@reduxjs/toolkit';
import iOS from '../../../common/isIos';
import {api} from '../../../services';
import {facturaSucursal} from '../../../services/dto/factura';
import {RootState} from '../../store';
import {setPagination} from './index';

type fetchFacturaProps = {
  page: number;
  perPage: number;
};
export const fetchFactura = createAsyncThunk<
  facturaSucursal[],
  fetchFacturaProps | undefined,
  {state: RootState}
>(
  'diario-ventas/fetchFactura',
  async (data = {page: 1, perPage: 50}, thunkAPI) => {
    const {
      restauranteSlice: {sucursalSeleccionada, cajaEmpleado, cargoEmpleado},
      diarioVentasSlice: {
        fecha_hora_final,
        fecha_hora_inicial,
        facturaCaja,
        estado_factura,
      },
    } = thunkAPI.getState();
    let response;
    if (!fecha_hora_final || !fecha_hora_inicial)
      return thunkAPI.rejectWithValue(
        'fecha_hora_final o fecha_hora_inicial no definidos',
      );

    if (cargoEmpleado && cargoEmpleado.id_cargo === 1 && sucursalSeleccionada) {
      response = await api.getFacturaSucursal(
        fecha_hora_inicial.toISOString(),
        fecha_hora_final.toISOString(),
        sucursalSeleccionada.id_sucursal,
        estado_factura,
        data.page,
        data.perPage,
      );
    } else {
      if (facturaCaja) {
        if (!cajaEmpleado)
          return thunkAPI.rejectWithValue('Usuario no tiene caja asignada');
        response = await api.getFacturaSucursalByCaja(
          fecha_hora_inicial.toISOString(),
          fecha_hora_final.toISOString(),
          cajaEmpleado.caja.id_caja_sucursal,
          estado_factura,
          data.page,
          data.perPage,
        );
      } else {
        if (!sucursalSeleccionada)
          return thunkAPI.rejectWithValue('No hay sucursal seleccionada');
        response = await api.getFacturaSucursal(
          fecha_hora_inicial.toISOString(),
          fecha_hora_final.toISOString(),
          sucursalSeleccionada.id_sucursal,
          estado_factura,
          data.page,
          data.perPage,
        );
      }
    }
    if (!response)
      return thunkAPI.rejectWithValue('No hay sucursal seleccionada');
    if (response.meta) {
      const {current_page, last_page, total, per_page} = response.meta;
      thunkAPI.dispatch(
        setPagination({
          current: current_page,
          total: last_page,
          last: total,
          per_page: per_page

  

        }),
      );
    }
    return response.data;
  },
);

export const getFactura = createAsyncThunk<
  null,
  {id_factura: number; action: 'open' | 'download'},
  {state: RootState}
>('diario-ventas/getFactura', async (data, thunkAPI) => {
  const response = await api.getFactura(data.id_factura);

  if (!response) return thunkAPI.rejectWithValue('error al obtener la factura');

  if (iOS()) data.action = 'download';

  if (data.action === 'open') {
    const url = window.URL.createObjectURL(response);
    window.open(url, '_blank');
    return null;
  }

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(response);
  link.download = `${data.id_factura}.pdf`;
  link.click();
  return null;
});

export const anularFactura = createAsyncThunk<null, number, {state: RootState}>(
  'diario-ventas/anularFactura',
  async (data, thunkAPI) => {
    const {
      restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
    } = thunkAPI.getState();
    if (!data) return thunkAPI.rejectWithValue('No se ha definido la factura');
    if (!empleadoAsignadoSucursalSeleccionado)
      return thunkAPI.rejectWithValue('No hay sucursal seleccionada');
    const response = await api.putAnularFactura(
      data,
      empleadoAsignadoSucursalSeleccionado?.sucursal.restaurante_id,
    );

    if (!response)
      return thunkAPI.rejectWithValue('error al anular la factura');

    thunkAPI.dispatch(fetchFactura());

    return null;
  },
);
